<template>
    <dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ artist.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.artists.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-row v-if="artistUser">
                            <v-col lg="4">
                                <v-select
                                    :items="pricePlansFiltered"
                                    label="Price plan"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    v-model="pricePlan"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col lg="4">
                                <v-text-field
                                    v-model="artist.name"
                                    outlined
                                    readonly
                                    label="Stage Name"
                                    placeholder=""
                                />
                            </v-col>

                            <v-col>
                                <type-select
                                    v-if="!loading"
                                    outlined
                                    v-model="artist.type"
                                />
                            </v-col>

                            <v-col>
                                <v-select
                                    multiple
                                    outlined
                                    :items="genres"
                                    label="Genres"
                                    item-text="name"
                                    item-value="id"
                                    v-model="artist.genres"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col>
                                <city-selector
                                    v-if="!loading"
                                    return-object
                                    v-model="artist.city"
                                    outlined
                                    hide-details="auto"
                                />
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="artist.website_url"
                                    outlined
                                    label="Website Url"
                                />
                            </v-col>

                            <v-col>
                                <v-text-field
                                    v-model="artist.merch_url"
                                    outlined
                                    label="Merchandise Url"
                                />
                            </v-col>
                        </v-row>

                        <v-row>

                            <v-col>
                                <v-text-field
                                    v-model="artist.subtitle"
                                    outlined
                                    label="Subtitle"
                                    placeholder=""
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    v-model="artist.bio"
                                    outlined
                                    label="Bio"
                                    placeholder=""/>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="2">
                                <v-btn @click="handleArtistUpdate" color="success">Save</v-btn>
                            </v-col>
                            <v-col lg="2" class="text-right">
                                <v-btn :to="{name: 'artist.public', params: {slug: artist.slug}}">
                                    <v-icon>mdi-eye</v-icon>
                                    View
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </dashboard-layout>
</template>

<script>
import CitySelector from "@/components/CitySelector";
import Artist from "@/models/Artist";
import Genre from "@/models/Genre";
import TypeSelect from "@/components/artist/TypeSelect";
import DashboardLayout from "@/layouts/DashboardLayout";
import PricePlan from "@/models/PricePlan";
import User from "@/models/User";

export default {
    name: "users-show",
    components: {DashboardLayout, TypeSelect, CitySelector},
    data: function () {
        return {
            artist: {},
            loading: true,
            genres: [],
            pricePlans: [],
            pricePlan: null,
            artistUser: null
        }
    },
    async mounted() {
        this.loading = true

        this.artist = await Artist.find(this.$route.params.id)

        if (this.artist.user_id) {
            await this.loadArtistUser()
        }

        this.artist.genres = this.artist.genres ?? []
        this.genres = await Genre.get()
        this.pricePlans = await PricePlan.custom('/price-plans/public').get()

        this.loading = false

        this.setGenres()
    },
    methods: {
        handleArtistUpdate() {
            this.artist.save()

            if (this.pricePlan && this.pricePlan !== parseInt(this.artistUser['price_plan_id'])) {
                this.artistUser.price_plan_id = this.pricePlan
                this.artistUser.save()
            }
        },
        setGenres() {
            const artistGenres = [];

            for (const genre of this.artist.genres) {
                artistGenres.push(genre.id)
            }

            this.artist.genres = artistGenres
        },

        async loadArtistUser() {
            this.artistUser = await User.find(this.artist.user_id)

            if (this.artistUser && this.artistUser.price_plan_id) {
                this.pricePlan = parseInt(this.artistUser.price_plan_id)
            }

        }
    },
    watch: {
        "artist.city": function (val) {
            this.artist.city_id = val.id
        }
    },
    computed: {
        pricePlansFiltered() {
            return this.pricePlans.filter(p => p.name.toLowerCase().startsWith('artist'))
        },
    }
}
</script>

<style scoped>

</style>
